import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from 'gatsby';
import PostLink from './PostLink';
import { Box, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { POSTS_TO_SHOW } from '../const/index';
import { InView } from 'react-intersection-observer';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 200,
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
  textHint: {
    color: theme.palette.text.hint,
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
}));

function Main(props) {
  const classes = useStyles();
  const { title } = props;
  const [loading, setLoading] = React.useState(false);
  const showingMore = get(props, 'showingMore', false);
  const postsToShow = get(props, 'postsToShow', 12);

  const result = useStaticQuery(graphql`
    query {
      posts: allMdx(
        skip: 2
        filter: {
          fields: { source: { eq: "post" } }
          frontmatter: { visible: { eq: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 20
      ) {
        edges {
          node {
            ...PostLink_details
          }
        }
      }
    }
  `);

  const posts = get(result, 'posts.edges', []);

  const handleLoadingMore = () => {
    const length = postsToShow + POSTS_TO_SHOW;

    props.dispatch({
      type: 'onPaging',
      payload: {
        showingMore: posts.length > length,
        postsToShow: length,
      },
    });

    setLoading(false);
  };

  const onInView = (inView) => {
    setLoading(inView);

    setTimeout(() => {
      if (inView) {
        handleLoadingMore();
      }
    }, 1000);
  };

  const postsList = React.useMemo(() => posts.slice(0, postsToShow), [
    postsToShow,
    posts,
  ]);

  return (
    <Grid item xs={12} md={8}>
      <Typography variant="h5" className={classes.mb2}>
        {title}
      </Typography>
      <Divider className={classes.mb2} />

      {postsList.map(({ node }) => (
        <PostLink key={node.id} post={node} />
      ))}

      {showingMore && (
        <InView threshold={1} onChange={onInView}>
          {loading && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={4}
              mx={{ xs: 2, md: 0 }}
            >
              <CircularProgress color="secondary" />
            </Box>
          )}
        </InView>
      )}
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string,
};

export default connect((state) => ({
  showingMore: state.showingMore,
  postsToShow: state.postsToShow,
}))(Main);
