import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import LinkIcon from '@material-ui/icons/Link';
import copy from 'copy-to-clipboard';
import MuiAlert from '@material-ui/lab/Alert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { downloadURI } from '../utils/utils';
import CanvasPoster from './CanvasPoster';
import CircularProgress from '@material-ui/core/CircularProgress';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const TransitionZoom = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  transparent: {
    boxShadow: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dialogActions: {
    position: 'sticky',
    left: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ShareButton(props) {

  const {
    excerpt,
    title,
    date,
    qrBase64Img,
    postBanner,
    mediaKey,
  } = props;

  const [linkHref, setLinkHref] = useState('');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackbar, setSnackbar] = useState(false);

  const [openPoster, setOpenPoster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coverBase64, setCoverBase64] = useState('');

  const classes = useStyles();

  // const mediaKey = useWidth();

  useEffect(() => {
    try {
      setLinkHref(window.location.href);
    } catch (e) {
    }
  }, []);

  const handleOpenLink = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleCloseLink = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const handleCopy = () => {
    copy(linkHref);
    handleCloseLink();
    setSnackbar(true);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenPoster = () => {
    setAnchorEl(null);

    // 如果已经生成过了，直接显示，否则生成海报
    if (coverBase64) {
      setOpenPoster(true);
    } else {
      setLoading(true);
    }
  };

  // useOverHeight(openPoster);

  const handleClosePoster = () => {
    setOpenPoster(false);
    setLoading(false);
  };

  const handleSave = () => {
    downloadURI(coverBase64);
    handleClosePoster();
  };

  const onLoadCanvasPoster = (base64) => {
    setLoading(false);
    setCoverBase64(base64);
    setOpenPoster(true);
  };


  return (
    <>
      <Tooltip title="分享" placement="bottom">
        <IconButton onClick={handleOpenMenu}>
          <ShareIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleOpenPoster}>生成海报</MenuItem>
        <MenuItem onClick={handleOpenLink}>链接分享</MenuItem>
      </Menu>

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        TransitionComponent={Transition}
        onClose={handleCloseLink}
      >
        <DialogTitle
          disableTypography
          id="alert-dialog-slide-title"
          className={classes.dialogTitle}
        >
          <LinkIcon style={{ marginRight: 12 }} />
          <Typography variant="h6">分享链接</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            value={linkHref}
            onFocus={(e) => e.target.select()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLink} color="primary">
            取消
          </Button>
          <Button onClick={handleCopy} color="secondary">
            复制
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">&nbsp;&nbsp;复制成功&nbsp;&nbsp;</Alert>
      </Snackbar>

      <Dialog
        open={openPoster}
        onClose={handleClosePoster}
        fullWidth
        maxWidth="xs"
        classes={{ paper: classes.transparent }}
        TransitionComponent={TransitionZoom}
      >
        <Box
          sx={{
            margin: 'auto',
            width: '100%',
            height: 'auto',
            overflowX: 'hidden',
          }}
        >
          <img
            width="100%"
            style={{ objectFit: 'contain' }}
            src={coverBase64}
            alt=""
          />
        </Box>

        <DialogActions className={classes.dialogActions}>
          {'xs' === mediaKey && (
            <Typography
              style={{ marginRight: 'auto' }}
              variant="body1"
              color="textSecondary"
            >
              长按保存
            </Typography>
          )}

          <Button onClick={handleClosePoster} color="primary">
            关闭
          </Button>

          {'xs' !== mediaKey && (
            <Button onClick={handleSave} color="secondary" variant="contained">
              保存
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={loading}>
        <Box
          flexDirection="column"
          display="flex"
          sx={{ alignItems: 'center' }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="overline" style={{ marginTop: 4 }}>
            生成中，请稍后
          </Typography>
        </Box>
      </Backdrop>

      {loading && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            opacity: 0,
            zIndex: -999,
            // zIndex: 999,
            // top: 0,
            // left: 0,
            // background: 'pink',
          }}
        >
          <CanvasPoster
            onLoad={onLoadCanvasPoster}
            postBanner={postBanner}
            excerpt={excerpt}
            title={title}
            date={date}
            qrBase64Img={qrBase64Img}
            mediaKey={mediaKey}
          />
        </div>
      )}
    </>
  );
}

export default ShareButton;
