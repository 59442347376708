const mapping = new Map([
  [
    'essay',
    {
      title: '短文',
      cover: '/cate/essay/',
      time: '2020-10-15',
      author: 'acuariol',
      description: '短文',
    },
  ],
  [
    'journal',
    {
      title: '日志',
      cover: '/cate/journal/',
      time: '2020-10-15',
      author: 'acuariol',
      description: '日志',
    },
  ],
  [
    'developing',
    {
      title: 'Developing',
      cover: '/cate/developing/',
      time: '2020-10-15',
      author: 'acuariol',
      description: '前端/技术笔记',
    },
  ],
]);

module.exports = mapping;
