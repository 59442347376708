import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function CommonBannerImg({ img, smDown }) {
  if (!img) return <div />;
  return (
    <>
      {!smDown && <GatsbyImage alt="" style={{ height: 320 }} image={img} />}

      {smDown && <GatsbyImage alt="" style={{ height: 200 }} image={img} />}
    </>
  );
}
