import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { navigateTo } from '../utils/utils';
import { get } from 'lodash';
import clsx from 'clsx';
import { graphql } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  cardDetails: {
    flex: 1,
    height: 188,
    overflow: 'hidden',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  cardMedia: {
    width: 200,
  },
  textDisabled: {
    color: theme.palette.text.secondary,
  },
  textHint: {
    color: theme.palette.text.hint,
  },
  marginBottomSpacing3: {
    marginBottom: theme.spacing(5),
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function PostLink(props) {
  const { post } = props;

  const classes = useStyles();
  const image = getImage(post.frontmatter.postBanner);
  const excerpt = get(post, 'excerpt');
  return (
    <CardActionArea
      className={classes.marginBottomSpacing3}
      onClick={() => navigateTo({ path: post.slug })}
    >
      <Card className={classes.card}>
        {image && (
          <Hidden smUp>
            <GatsbyImage
              image={image}
              style={{ height: 200, width: '100%' }}
              alt=""
            />
          </Hidden>
        )}
        <div className={classes.cardDetails}>
          <div className={classes.main}>
            <Typography
              component="h6"
              variant="h6"
              gutterBottom
              className="nowrap2"
            >
              {post.frontmatter.title}
            </Typography>

            <Typography
              variant="subtitle1"
              gutterBottom
              className={clsx(classes.textDisabled, 'nowrap3')}
            >
              {excerpt}
            </Typography>
          </div>
          <Typography variant="subtitle2" color="textSecondary">
            {post.frontmatter.date}
          </Typography>
        </div>
        {image && (
          <Hidden xsDown>
            <GatsbyImage
              image={image}
              alt=""
              style={{ height: 188, width: 188 }}
            />
          </Hidden>
        )}
      </Card>
    </CardActionArea>
  );
}

export const postFragment = graphql`
  fragment PostLink_details on Mdx {
    id
    slug
    excerpt(pruneLength: 150, truncate: true)
    frontmatter {
      date(formatString: "YYYY-MM-DD")
      title
      description
      cover
      createPage
      outUrl
      url
      postBanner {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
