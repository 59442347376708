import React from 'react';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  baseLayout: {
    // minHeight:'100vh',
    // height:'auto',
    // background:'#577490'
  },
});

export default function BaseLayout({ className, children, ...reset }) {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <div className={clsx(classes.baseLayout, className)} {...reset}>
        {children}
      </div>
    </Container>
  );
}

BaseLayout.propTypes = {
  className: PropTypes.string,
};
