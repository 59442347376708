import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import BaseLayout from '../layout/BaseLayout';
import { GatsbyImage } from 'gatsby-plugin-image';
import { get } from 'lodash';
import mapping from '../const/cateMapping';
import { navigateTo } from '../utils/utils';
import NProgress from 'nprogress';
import Replacement from '../components/Replacement';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';

const MyCard = function ({ classes, data, imgObg }) {
  const { fieldValue, totalCount } = data;
  const mappingVal = mapping.get(fieldValue) || {
    title: `未分类 | ${fieldValue}`,
    description: '- -',
    time: '- -',
  };

  const nav = () => navigateTo({ path: `/categories/${fieldValue}` });
  const fluid = get(imgObg, `${fieldValue}.gatsbyImageData`, null);

  return (
    <Card className={classes.cardRoot}>
      <CardActionArea onClick={nav}>
        {fluid ? (
          <GatsbyImage style={{ height: 320 }} image={fluid} alt="" />
        ) : (
          <Replacement style={{ height: 320 }} />
        )}
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
            {mappingVal.title}
          </Typography>
          <Typography variant="subtitle1" component="p">
            {mappingVal.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.action}>
        <Typography variant="body1" component="p" color="textSecondary">
          {' '}
          {mappingVal.time}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          color="textSecondary"
          className={classes.total}
        >
          文章数：{totalCount}
        </Typography>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    position: 'relative',
  },
  content: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
  },
  total: {
    marginLeft: 'auto',
  },
  action: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
  },
}));

export default function Categories(props) {
  const classes = useStyles();
  useEffect(() => {
    NProgress.done();
  }, []);
  const {
    data: { allMdx, site, ...reset },
  } = props;
  const { title } = site.siteMetadata;
  const arr = get(allMdx, 'group', []);
  const el = arr.map((o) => {
    return (
      <Grid item md={6} xs={12} key={o.fieldValue}>
        <MyCard classes={classes} data={o} imgObg={reset} />
      </Grid>
    );
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>{`所有专辑 - ${title}`}</title>
        <meta name="description" content={`所有专辑 - ${title}`} />
      </Helmet>
      <BaseLayout>
        <Typography
          className={classes.title}
          variant="h5"
          component="h5"
          gutterBottom
        >
          所有专辑
        </Typography>
        <Divider />
        <div className={classes.content}>
          <Grid container spacing={4}>
            {el}
          </Grid>
        </div>
      </BaseLayout>
      <Footer
        title="Acuariol.com"
        description="Captain, My Captain! 及时行乐"
      />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query CateQuery {
    essay: imageSharp(
      fields: { slug: { eq: "/cate/essay/" }, source: { eq: "image" } }
    ) {
      id
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
    }
    journal: imageSharp(
      fields: { slug: { eq: "/cate/journal/" }, source: { eq: "image" } }
    ) {
      id
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
    }
    developing: imageSharp(
      fields: { slug: { eq: "/cate/developing/" }, source: { eq: "image" } }
    ) {
      id
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
    }
    allMdx(filter: { frontmatter: { visible: { eq: true } } }) {
      group(field: fields___category) {
        fieldValue
        totalCount
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
