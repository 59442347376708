import React from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';

function Pull({ children }) {
  const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }));

  const bind = useDrag(({ down, movement }) => {
    // console.log(down, movement)
    set({ xy: down ? movement : [0, 0] });
  });

  return (
    <animated.div
      {...bind()}
      style={{
        transform: xy.interpolate((x, y) => `translate3d(${x}px, ${y}px, 0)`),
        position: 'relative',
        zIndex: 10,
      }}
    >
      {children}
    </animated.div>
  );
}

export default Pull;
