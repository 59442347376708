import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useTags from '../hook/useTags';
import { get } from 'lodash';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { navigateTo } from '../utils/utils';
import useMoreTags from '../hook/userMoreTags';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tagsBox: {
    display: 'flex',

    flexWrap: 'wrap',
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function AllTags({ targetTag }) {
  const classes = useStyles();

  const result = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: { visible: { eq: true }, createPage: { eq: true } }
        }
      ) {
        edges {
          node {
            frontmatter {
              tags
            }
          }
        }
      }
    }
  `);

  const mapping = useTags(get(result, 'allMdx.edges', []));
  const tags = useMoreTags(Object.keys(mapping), {
    exclude: [targetTag],
    limit: 4,
  });

  return (
    <Fragment>
      <div className={clsx(classes.tagsBox, classes.my2)}>
        {tags.map((tagName) => (
          <Chip
            icon={<LocalOfferOutlinedIcon fontSize="small" />}
            clickable
            label={tagName}
            onClick={() => navigateTo({ path: `/tag/${tagName}` })}
            color="secondary"
            variant="outlined"
            key={tagName}
            className={classes.chip}
          />
        ))}
      </div>
    </Fragment>
  );
}
