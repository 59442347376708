import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import NProgress from 'nprogress';
import Seo from '../components/seo/Seo';
import BaseLayout from '../layout/BaseLayout';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { useMediaQuery } from '@material-ui/core';
import { get } from 'lodash';
import Pagination from '@material-ui/lab/Pagination';
import { navigateTo } from '../utils/utils';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ScaleModal from '../components/ScaleModal';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import CommonBannerImg from '../components/CommonBannerImg';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
  },
  card: {
    position: 'relative',
  },
  marginBottomSpacing3: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  ul: {
    [theme.breakpoints.down('xs')]: {
      paddingInlineStart: 20,
    },
  },
  allYear: {
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
    },
  },
}));

const Link = ({ post, classes }) => {
  return (
    <CardActionArea
      className={classes.marginBottomSpacing3}
      onClick={() => navigateTo({ path: post.slug })}
    >
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {post.frontmatter.date}
            {/*&nbsp;&nbsp;({post.frontmatter.fromNow})*/}
          </Typography>
          <Typography component="h6" variant="h6" className="nowrap2">
            {post.frontmatter.title}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

const ArchivesPage = ({ data, pageContext }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { site, banner: archivesBanner } = data;
  const { title } = site.siteMetadata;

  const { currentPage, numPages, group, allYear } = pageContext;
  const archives = {};

  group.forEach(({ node }) => {
    const year = new Date(node.frontmatter.date).getFullYear();
    if (!archives[`year${year}`]) {
      archives[`year${year}`] = [];
    }
    archives[`year${year}`].push(node);
  });

  const banner = get(archivesBanner, 'gatsbyImageData');

  const seo = {
    postMeta: {
      title: `归档 - ${title}`,
      description: `归档 - ${title}`,
    },
  };

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    NProgress.done();
  }, []);

  const handlePaginationChange = (_, page) => {
    const path = page === 1 ? `/archives` : `/archives/page/${page}`;
    navigateTo({ path }).then((_) => {
    });
  };

  const handleYearClick = (year) => {
    const path = `/archive/${year}`;
    navigateTo({ path }).then((_) => {
    });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Seo {...seo} />

      <BaseLayout>
        <CommonBannerImg img={banner} smDown={smDown}/>

        <Box display="flex" justifyContent="flex-end" m={1}>
          <IconButton onClick={() => setOpen(true)}>
            <AppsIcon />
          </IconButton>
        </Box>

        <ScaleModal handleClose={() => setOpen(false)} open={open}>
          <Card className={classes.allYear}>
            <CardHeader title="所有年份" />

            <CardContent>
              {(allYear || []).map((date) => {
                const year = date.substr(4);
                return (
                  <Button
                    key={year}
                    size="large"
                    color="secondary"
                    onClick={() => handleYearClick(year)}
                  >
                    <Typography variant="h5">{year}年</Typography>
                  </Button>
                );
              })}
            </CardContent>
          </Card>
        </ScaleModal>

        <div className={classes.content}>
          {Object.keys(archives).map((date) => {
            const year = date.substr(4);

            const archiveList = archives[date].map((node) => {
              return <Link key={node.id} post={node} classes={classes} />;
            });

            return (
              <div className="archives-item" key={year}>
                <Button
                  size="large"
                  startIcon={<EventNoteIcon />}
                  color="secondary"
                  onClick={() => handleYearClick(year)}
                >
                  <Typography variant="h5">{year}年</Typography>
                </Button>
                <ul className={classes.ul}>{archiveList}</ul>
              </div>
            );
          })}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            defaultPage={currentPage}
            onChange={handlePaginationChange}
            count={numPages}
            variant="outlined"
            color="primary"
            shape="rounded"
            size={smDown ? 'medium' : 'large'}
          />
        </div>
      </BaseLayout>
      <Footer
        title="Acuariol.com"
        description="Captain, My Captain! 及时行乐"
      />
    </React.Fragment>
  );
};

export default ArchivesPage;

export const pageQuery = graphql`
  query archivesQuery {
    site {
      siteMetadata {
        title
      }
    }
    banner: imageSharp(
      fields: { slug: { eq: "/archiveBanner/" }, source: { eq: "image" } }
    ) {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`;
