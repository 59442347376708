import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';
import { navigateTo } from '../utils/utils';

const useStyles = makeStyles(() => ({
  card: {
    position: 'relative',
  },
}));

function Link({ node, label, isNext, mdUp }) {
  const classes = useStyles();

  // const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <CardActionArea onClick={() => navigateTo({ path: node.slug })}>
      <Card className={classes.card}>
        <Box sx={{ padding: 16 }}>
          <Typography
            align={isNext && mdUp ? 'right' : 'left'}
            variant="subtitle2"
            color="textSecondary"
            gutterBottom
          >
            {label}
          </Typography>
          <Typography
            align={isNext && mdUp ? 'right' : 'left'}
            variant="subtitle1"
            className="nowrap1"
          >
            {node.frontmatter.title}
          </Typography>

          <Typography
            align={isNext && mdUp ? 'right' : 'left'}
            variant="body2"
            className="nowrap1"
            color="textSecondary"
          >
            {node.frontmatter.date}
          </Typography>
        </Box>
      </Card>
    </CardActionArea>
  );
}

export default function NextPost({ next, previous, mdUp }) {
  // const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {previous && <Link node={previous} label="上一篇" mdUp={mdUp} />}
        </Grid>
        <Grid item xs={12} md={6}>
          {next && <Link node={next} label="下一篇" isNext mdUp={mdUp} />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

NextPost.propTypes = {
  next: PropTypes.object,
  previous: PropTypes.object,
};
