import React, { useEffect, useState } from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  rootScrollTop: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    transition: 'all 0.2s ease-in',
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    zIndex: 99,
  },
}));

function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();

  const [scrollControl, setControl] = useState(undefined);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const SmoothScroll = require('smooth-scroll');
      setControl(new SmoothScroll());
    }
  }, []);

  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 1000,
  });

  const handleClick = (event) => {
    let anchor;

    try {
      anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor',
      );
    } catch (e) {
    }

    if (anchor) {
      scrollControl &&
      scrollControl.animateScroll(anchor, null, {
        speed: 600,
        easing: 'easeOutCubic',
        updateURL: false,
      });
      // anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.rootScrollTop}
      >
        {children}
      </div>
    </Zoom>
  );
}

export default ScrollTop;
