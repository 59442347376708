import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../layout/BaseLayout';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { getImage } from 'gatsby-plugin-image';
import categoriesMapping from '../const/cateMapping';
import Grid from '@material-ui/core/Grid';
import PostLike from '../components/PostLink';
import NProgress from 'nprogress';
import useMoreCategory from '../hook/useMoreCategory';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { navigateTo } from '../utils/utils';
import Seo from '../components/seo/Seo';
import Footer from '../components/Footer';
import { useMediaQuery } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import CommonBannerImg from '../components/CommonBannerImg';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {},
  content: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
  },
  card: {
    marginBottom: theme.spacing(4),
  },
}));

export default function CategoryTemplate(props) {
  const classes = useStyles();
  const {
    data: { imageSharp, allMdx, site },
    pageContext: { category, currentPage, numPages, totalCount },
  } = props;
  const { title } = site.siteMetadata;

  const cateInfo = categoriesMapping.get(category) || { title: '未分类' };
  const banner = getImage(imageSharp);

  const moreCategory = useMoreCategory({ exclude: [category] });
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    NProgress.done();
  }, []);

  const seo = {
    postMeta: {
      title: `${cateInfo.title} | 专辑 - ${title}`,
      description: cateInfo.description,
    },
  };

  const handlePaginationChange = (_, page) => {
    const path =
      page === 1 ? `/categories/developing` : `/categories/developing/${page}`;
    navigateTo({ path }).then((_) => {
    });
  };

  return (
    <React.Fragment>
      <Seo {...seo} />

      <BaseLayout>
        <CommonBannerImg img={banner} />

        <Typography
          className={classes.title}
          variant="h5"
          component="h5"
          gutterBottom
        >
          {cateInfo.title} | 专辑 ( {totalCount}篇文章)
        </Typography>
        <Divider />
        <div className={classes.content}>
          <Grid container spacing={6}>
            <Grid item md={8} xs={12}>
              {(
                (allMdx.group && allMdx.group[0] && allMdx.group[0].edges) ||
                []
              ).map(({ node: post }) => (
                <PostLike post={post} key={post.id} />
              ))}

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  defaultPage={currentPage}
                  onChange={handlePaginationChange}
                  count={numPages}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  size={smDown ? 'medium' : 'large'}
                />
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className={classes.card}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h6"
                  color="textSecondary"
                >
                  推荐专辑
                </Typography>
                <Divider />
              </div>
              {moreCategory.map((category) => (
                <Card
                  className={classes.card}
                  key={category.key}
                  onClick={() =>
                    navigateTo({ path: `/categories/${category.key}` })
                  }
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h6">
                        {category.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {category.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Grid>
          </Grid>
        </div>
      </BaseLayout>
      <Footer
        title="Acuariol.com"
        description="Captain, My Captain! 及时行乐"
      />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query CategoryPage(
    $category: String
    $cover: String
    $skip: Int!
    $limit: Int!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: $limit
      skip: $skip
      filter: {
        fields: { category: { eq: $category } }
        frontmatter: { visible: { eq: true }, createPage: { eq: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      group(field: fields___category) {
        totalCount
        edges {
          node {
            ...PostLink_details
          }
        }
      }
    }
    imageSharp(fields: { source: { eq: "image" }, slug: { eq: $cover } }) {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`;
