import { createStore as reduxCreateStore } from 'redux';
import { POSTS_TO_SHOW } from '../const/index';

let theme;
let fontSize;

function getInitState() {
  try {
    const local = localStorage.getItem('theme');
    if (['light', 'dark'].includes(local)) {
      theme = local;
    } else {
      theme = 'light';
    }

    fontSize = localStorage.getItem('fontSize') || 16;
  } catch (e) {
  }
  return {
    theme: theme,
    fontSize: Number(fontSize),
    showingMore: true,
    postsToShow: POSTS_TO_SHOW,
  };
}

function reducer(state, { type, payload }) {
  switch (type) {
    case 'ToggleTheme':
      try {
        localStorage.setItem('theme', payload.theme);
      } catch (err) {
      }
      return { ...state, theme: payload.theme };
    case 'setFontSize':
      const size = payload.value;
      try {
        localStorage.setItem('fontSize', size);
      } catch (err) {
      }
      return { ...state, fontSize: size };
    case 'resetStore':
      try {
        localStorage.clear();
      } catch (err) {
      }
      return { ...state, ...getInitState() };
    case 'onPaging':
      return { ...state, ...payload };
    default:
      return state;
  }
}

const createStore = () => reduxCreateStore(reducer, getInitState());

export default createStore;
