import animType from 'rc-texty/lib/animTypes';
import { random } from 'lodash';

const types = Object.keys(animType);
const modes = ['smooth', 'reverse', 'random', 'sync'];

function useRandomTextAni() {
  const r1 = random(0, types.length);
  const r2 = random(0, modes.length);

  return {
    type: types[r1],
    mode: modes[r2],
  };
}

export default useRandomTextAni;
