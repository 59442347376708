// import { each, get } from 'lodash';
const { each, get } = require('lodash');

function useTags(edges = []) {
  const mapping = {};

  each(edges, ({ node }) => {
    const tags = get(node, 'frontmatter.tags', []);

    each(tags, (name) => {
      if (mapping[name]) {
        mapping[name] += 1;
      } else {
        mapping[name] = 1;
      }
    });
  });

  return mapping;
}

module.exports = useTags;
