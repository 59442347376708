import { navigate } from 'gatsby';
import NProgress from 'nprogress';
import { get } from 'lodash';

NProgress.configure({
  showSpinner: false,
  minimum: 0.09,
  speed: 180,
  trickleRate: 0.06,
  trickleSpeed: 160,
});

export async function navigateTo({ path, outUrl }) {
  NProgress.done();
  if (outUrl) {
    window.open(outUrl, '_blank');
    return;
  }

  if (path) {
    const p = path.replace(/^\//, '').replace(/\/$/, '');

    if (get(window.location, 'pathname') === `/${p}`) {
      return;
    }
    NProgress.start();
    await navigate(`/${p}`);

    // NProgress.done(); // 不用的原因 ： 导航虽然变了，但页面还没出来
    return `/${p}`;
  }
}

export function textLess(text = '', number = 40) {
  if (text.length >= number) {
    return text.substring(0, number) + '...';
  }
  return text;
}

export function logVersion() {
  const date = '2021-09-26 18:00';
  const a = 'background: #606060; color: #fff; border-radius: 3px 0 0 3px;';
  const b = 'background: #1475B2; color: #fff; border-radius: 0 3px 3px 0;';
  console.log(`%c Update Time : %c ${date} `, a, b);
}

export function removeTextSignal(str) {
  str = str.replace(/(^ )|( $)/g,'');
  str = str.replace(/(^\n)|(\n$)/g,'');
  str = str.replace(/(^\t)|(\t$)/g,'');
  return str;
}

export function date2Poster(str = '') {
  if (str.indexOf('-') >= 0) {
    const arr = str.split('-');

    return [`${arr[0]}/${arr[1]}`, arr[2]];
  }
  if (str.indexOf('/') >= 0) {
    const arr = str.split('-/');

    return [`${arr[0]}/${arr[1]}`, arr[2]];
  }

  return ['', ''];
}

export function downloadURI(uri, name) {
  let a = document.createElement('a'); // 生成一个a元素
  a.download = name || 'photo'; // 设置图片名称
  a.href = uri; // 将生成的URL设置为a.href属性
  a.dispatchEvent(new MouseEvent('click')); // 触发a的单击事件

  a = null;
}
