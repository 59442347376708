import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import BaseLayout from '../layout/BaseLayout';
import Typography from '@material-ui/core/Typography';
import Footer from '../components/Footer';
import FeaturedPost from '../components/FeaturedPost';
import Main from '../components/Main';
import Sidebar from '../components/Sidebar';
import { makeStyles } from '@material-ui/core/styles';
import GitHubIcon from '@material-ui/icons/GitHub';
import NProgress from 'nprogress';
import Seo from '../components/seo/Seo';
import InstagramIcon from '@material-ui/icons/Instagram';
import Divider from '@material-ui/core/Divider';
import HomeSwiper from '../components/HomeBanner';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(5),
  },
}));

const sidebar = {
  social: [
    { name: 'GitHub', icon: GitHubIcon },
    // { name: 'Twitter', icon: TwitterIcon },
    // { name: 'Facebook', icon: FacebookIcon },
    { name: 'Instagram', icon: InstagramIcon },
  ],
};

export default function Home() {
  const classes = useStyles();
  useEffect(() => {
    NProgress.done();
  }, []);

  return (
    <React.Fragment>
      <Seo />
      <BaseLayout>
        <div className={classes.mb1}>
          <HomeSwiper />
        </div>

        <Typography variant="h5" gutterBottom className={classes.mb2}>
          最近更新
        </Typography>
        <Divider className={classes.mb2} />

        <FeaturedPost />

        <Grid container spacing={8}>
          <Main title="文章" />
          <Sidebar
            title={sidebar.title}
            description={sidebar.description}
            archives={sidebar.archives}
            social={sidebar.social}
          />
        </Grid>
      </BaseLayout>

      <Footer
        title="Acuariol.com"
        description="Captain, My Captain! 及时行乐"
      />
    </React.Fragment>
  );
}
