function useImgUnDrag(id) {
  let imgs = document.getElementById(id).getElementsByTagName('img');

  return () => {
    for (let i = 0; i < imgs.length; i++) {
      imgs[i].ondragstart = function (e) {
        e.preventDefault();
        return false;
      };
    }
  };
}

export default useImgUnDrag;
