import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Helmet } from 'react-helmet';
import { Container } from '@material-ui/core';
import { createTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import aceTheme from '../theme';
import useLocalColorScheme from '../hook/useLocalColorScheme';
import ScrollTop from '../components/ScrollTop';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Header from '../components/Header';
import { logVersion } from '../utils/utils';

function ThemeLayout({ children }) {
  const themeStyle = useLocalColorScheme();
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () => responsiveFontSizes(createTheme(aceTheme[themeStyle])),
    [themeStyle],
  );

  useEffect(() => {
    let SmoothScroll;

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      SmoothScroll = require('smoothscroll-for-websites');
    }

    SmoothScroll &&
    SmoothScroll({
      animationTime: 500, // [ms]
      stepSize: 100, // [px]

      // Acceleration
      accelerationDelta: 90, // 50
      accelerationMax: 5, // 3
    });

    logVersion();
  }, []);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>ACUARIOL</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />

          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          {/*自定义字体*/}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Carter+One&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Concert+One&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <CssBaseline />
        <div id="back-to-top-anchor" style={{ visibility: 'hidden' }} />
        <Container maxWidth="lg">
          <Header title="Acuariol" />
        </Container>
        {children}
        <ScrollTop>
          <Fab
            color="secondary"
            size={'medium'}
            aria-label="scroll back to top"
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default connect((state) => ({ themeType: state.theme }))(ThemeLayout);
