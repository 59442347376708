import React from 'react';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { toUpper } from 'lodash';

const ReplacementBox = styled('div')({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: '100%',
  padding: '0 30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

function Replacement({ variant = 'h3', style, text }) {
  return (
    <ReplacementBox style={style}>
      <Typography variant={variant}>{toUpper(text || 'Acuariol')}</Typography>
    </ReplacementBox>
  );
}

export default Replacement;
