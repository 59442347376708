import mapping from '../const/cateMapping';
import { difference, random } from 'lodash';

function useMoreCategory({ exclude = [] }) {
  const keys = mapping.keys();
  const origin = difference([...keys], exclude);
  const limit = origin.length; //  origin.length -1
  const cate = [];
  const d = [];
  while (cate.length < limit) {
    const temp = difference(origin, cate);
    const key = temp[random(0, temp.length - 1)];
    cate.push(key);
    d.push({
      key,
      ...mapping.get(key),
    });
  }
  return d;
}

export default useMoreCategory;
