import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import NProgress from 'nprogress';
import Seo from '../components/seo/Seo';
import BaseLayout from '../layout/BaseLayout';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/Footer';
import Grid from '@material-ui/core/Grid';
import PostLike from '../components/PostLink';
import { getImage } from 'gatsby-plugin-image';
import { useMediaQuery } from '@material-ui/core';
import { toUpper } from 'lodash';
import AllTags from '../components/AllTags';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import Pagination from '@material-ui/lab/Pagination';
import { navigateTo } from '../utils/utils';
import CommonBannerImg from '../components/CommonBannerImg';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {},
  content: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
  },
  card: {
    marginBottom: theme.spacing(4),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: 'rgba(0,0,0,.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
}));

const TagPage = ({ data, pageContext }) => {
  const classes = useStyles();
  const { site, allMdx, tagsBanner } = data;
  const { title } = site.siteMetadata;
  const { edges } = allMdx;
  const { tag, currentPage, numPages, totalCount } = pageContext;
  const banner = getImage(tagsBanner);

  const seo = {
    postMeta: {
      title: `${tag} | 标签 - ${title}`,
      description: `${tag} | 标签 - ${title}`,
    },
  };

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    NProgress.done();
  }, []);

  const handlePaginationChange = (_, page) => {
    const path = page === 1 ? `/tag/${tag}` : `/tag/${tag}/${page}`;
    navigateTo({ path }).then((_) => {
    });
  };

  return (
    <React.Fragment>
      <Seo {...seo} />

      <BaseLayout>
        <div style={{ position: 'relative' }}>
          <CommonBannerImg img={banner} smDown={smDown} />
          <div className={classes.overlay}>
            <LocalOfferOutlinedIcon
              fontSize={smDown ? 'medium' : 'large'}
              style={{ marginRight: 8 }}
            />
            <Typography variant={smDown ? 'h2' : 'h1'}>{toUpper(tag)}</Typography>
          </div>
        </div>

        <Typography
          className={classes.title}
          variant="h5"
          component="h5"
          gutterBottom
        >
          {totalCount}篇文章
        </Typography>
        <Divider />

        <div className={classes.content}>
          <Grid container spacing={6}>
            <Grid item md={8} xs={12}>
              {
                (edges || []).map(({ node: post }) => (
                  <PostLike post={post} key={post.id} />
                ))
              }

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  defaultPage={currentPage}
                  onChange={handlePaginationChange}
                  count={numPages}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  size={smDown ? 'medium' : 'large'}
                />
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className={classes.card}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  color="textSecondary"
                >
                  推荐标签
                </Typography>
                <Divider />
                <AllTags targetTag={tag} />
              </div>
            </Grid>
          </Grid>
        </div>
      </BaseLayout>
      <Footer
        title="Acuariol.com"
        description="Captain, My Captain! 及时行乐"
      />
    </React.Fragment>
  );
};

export default TagPage;

export const pageQuery = graphql`
  query tagQuery($tag: [String!], $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    tagsBanner: imageSharp(
      fields: { slug: { eq: "/tagsBanner/" }, source: { eq: "image" } }
    ) {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
    }
    allMdx(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: frontmatter___date }
      filter: {
        frontmatter: {
          tags: { in: $tag }
          visible: { eq: true }
          createPage: { eq: true }
        }
      }
    ) {
      edges {
        node {
          ...PostLink_details
        }
      }
    }
  }
`;
