import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { animated, useSpring } from 'react-spring';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Sclae = React.forwardRef((props, ref) => {
  const { in: open, children, onEnter, onExited, ...other } = props;

  const style = useSpring({
    config: { duration: 150 },
    from: { opacity: 0, transform: 'scale(1.5)' },
    to: { opacity: open ? 1 : 0, transform: open ? 'scale(1)' : 'scale(1.5)' },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} {...other} style={{ ...style, outline: 0 }}>
      {children}
    </animated.div>
  );
});

Sclae.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

ScaleModal.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

export default function ScaleModal({ children, open, handleClose }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
        disableEnforceFocus
        disableAutoFocus
      >
        <Sclae in={open}>{children}</Sclae>
      </Modal>
    </div>
  );
}
