import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import { navigateTo } from '../utils/utils';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { get } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    height: 200,
    position: 'relative',
  },
  cardDetails: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    width: 200,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  textDisabled: {
    color: theme.palette.text.secondary,
  },
  mb1: {
    marginBottom: theme.spacing(5),
  },
  ribbon: {
    width: 166,
    height: 90,
    overflow: 'hidden',
    position: 'absolute',
    top: -3,
    right: -3,
    zIndex: 99,
  },
  ribbonText: {
    textAlign: 'center',
    transform: 'rotate(45deg)',
    position: 'absolute',
    padding: '2px 0 2px 0',
    width: 80,
    backgroundColor: '#FF8E53',
    backgroundImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    color: '#fff',
    top: 6,
    right: -22,
    '&:before, &:after': {
      content: '""',
      borderLeft: '3px solid transparent',
      borderRight: '3px solid transparent',
      position: 'absolute',
      bottom: -3,
      borderTop: '3px solid #FF8E53',
    },
    '&:before': {
      left: 0,
    },
    '&:after': {
      right: 0,
    },
  },
}));

function Post({ node, classes }) {
  const date = get(node, 'frontmatter.date', null);
  const isLatest = moment().subtract(8, 'days').isBefore(moment(date));
  const fixed = getImage(node.frontmatter.postBanner);
  const excerpt = get(node, 'excerpt');

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea onClick={() => navigateTo({ path: node.slug })}>
        <Card className={classes.card}>
          {fixed && (
            <Hidden xsDown>
              <GatsbyImage image={fixed} alt="" />
            </Hidden>
          )}

          <CardContent className={classes.cardDetails}>
            <div className={classes.title}>
              <Typography
                component="h6"
                variant="h6"
                className="nowrap1"
                gutterBottom
              >
                {node.frontmatter.title}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                {date}
              </Typography>
            </div>
            <Typography
              variant="body1"
              className={clsx('nowrap2', classes.textDisabled)}
            >
              {excerpt}
            </Typography>
          </CardContent>
        </Card>
        {isLatest && (
          <div className={classes.ribbon}>
            <Typography className={classes.ribbonText}>NEW</Typography>
          </div>
        )}
      </CardActionArea>
    </Grid>
  );
}

export default function FeaturedPost() {
  const classes = useStyles();
  const result = useStaticQuery(graphql`
    query {
      posts: allMdx(
        filter: {
          fields: { source: { eq: "post" } }
          frontmatter: { visible: { eq: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 2
      ) {
        edges {
          node {
            id
            slug
            excerpt(pruneLength: 100, truncate: true)
            frontmatter {
              date(formatString: "YYYY-MM-DD")
              title
              description
              cover
              createPage
              outUrl
              url
              postBanner {
                childImageSharp {
                  id
                  gatsbyImageData(
                    width: 200
                    height: 200
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className={classes.mb1}>
      <Grid container spacing={4}>
        {(result.posts.edges || []).map(({ node }) => (
          <Post key={node.id} node={node} classes={classes} />
        ))}
      </Grid>
    </div>
  );
}

FeaturedPost.propTypes = {
  node: PropTypes.object,
};
