import React from 'react';
import { date2Poster } from '../utils/utils';
import html2canvas from 'html2canvas';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  logoTitle: {
    // ...theme.
    fontSize: 24,
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
    },
  },
  title: {
    fontSize: 24,
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
    },
  },
  p: {
    fontSize: 15,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },

}));

const WIDTH = 430;

const HEIGHT = Number((WIDTH / 0.618).toFixed(0));

function CanvasPoster(props) {

  const { excerpt, title, date, qrBase64Img, postBanner, mediaKey } = props;

  const classes = useStyles();
  const theme = useTheme();
  const refBox = React.useRef();
  const [trigger, setTrigger] = React.useState(false);
  const [w, setW] = React.useState(WIDTH);
  const [h, setH] = React.useState(HEIGHT);

  const onLoad = () => {
    setTrigger(true);
    const { onLoad } = props;
    const { width, height } = refBox.current?.getBoundingClientRect();

    html2canvas(refBox.current, {
      allowTaint: true, // 是否允许跨域图像污染画布
      useCORS: true, // 是否尝试使用 CORS 从服务器加载图像
      scale: 2, // 用于渲染的比例。默认为浏览器设备像素比
      width,
      height,
      imageTimeout: 8000,
    })
      .then(function (canvas) {
        const base64 = canvas.toDataURL();
        onLoad(base64);
        console.log('Generate poster success');
      })
      .catch((e) => {
        console.error(e);
        onLoad('');
      })
      .finally(() => {
      });
  };

  React.useEffect(() => {
    const clearId = setTimeout(() => {
      if (!trigger) {
        onLoad();
      }
    }, 5000);

    return () => clearTimeout(clearId);
  }, [onLoad, trigger]);


  React.useLayoutEffect(() => {

    try {
      if (['xs'].includes(mediaKey)) {
        console.log('xs');
        const width = document.body.clientWidth;
        const height = Number(((width - 30) / 0.618).toFixed(0));
        setH(height);
        setW(width - 30);
      } else {
        setH(HEIGHT);
        setW(WIDTH);
      }

    } catch (e) {
      console.error(e);
      console.error('无法获取浏览器的宽');
    }
  }, [mediaKey]);


  const sleep = () => {
    setTimeout(() => {
      onLoad();
    }, 1000);
  };


  const time = date2Poster(date);

  const coverHeight = w;

  const imgSrc = getSrc(postBanner);

  return (
    <div
      ref={refBox}
      style={{
        margin: 'auto',
        width: w,
        height: 'auto',
        overflowX: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.background.default,
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            position: 'relative',
            fontSize: 0,
            width: w,
            height: coverHeight,
            overflow: 'hidden',
            boxSizing: 'border-box',
            background: '#f5f5f5',
          }}
        >
          {
            postBanner && (
              <img
                style={{
                  width: w,
                  height: coverHeight,
                }}
                alt=""
                src={imgSrc}
                onLoad={sleep}
              />
            )
          }

          <div
            style={{
              position: 'absolute',
              left: -2,
              top: -2,
              width: w + 2,
              height: coverHeight + 2,
              backgroundImage:
                'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.1),rgba(0,0,0,0.2),rgba(0,0,0,0.3), rgba(0,0,0,0.5))',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              justifyContent: 'space-between',
              fontSize: 0,
            }}
          >
            <div
              style={{
                padding: 15,
                display: 'flex',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
              }}
            >
              <p
                className={classes.logoTitle}
                style={{
                  margin: '0',
                  fontWeight: 'bold',
                  fontFamily: 'Carter One',
                  letterSpacing: 1.2,
                  color: '#fff',
                }}
              >
                Acuariol
              </p>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  boxSizing: 'border-box',
                }}
              >
                <p
                  className={classes.logoTitle}
                  style={{
                    color: '#fff',
                    margin: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {time[1]}
                </p>
                <div
                  style={{
                    width: 30,
                    height: 2,
                    background: '#fff',
                    margin: 0,
                    boxSizing: 'border-box',
                  }}
                />
                <p
                  className={classes.logoTitle}
                  style={{
                    color: '#fff',
                    margin: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {time[0]}
                </p>
              </div>
            </div>
            <div style={{ padding: 15, boxSizing: 'border-box' }}>
              <p
                className={classes.title}
                style={{
                  padding: '0 0 10px 0',
                  fontWeight: 'bold',
                  color: '#fff',
                  margin: 0,
                }}
              >
                {title}
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            boxSizing: 'border-box',
          }}
        >
          <p className={classes.p} style={{ margin: 0, flex: 1, padding: '15px 15px 65px 15px' }}>
            {excerpt}
          </p>
          <div
            style={{
              background: theme.palette.background.paper,
              height: 90,
              borderRadius: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxSizing: 'border-box',
              padding: 10,
              margin: '0 15px 30px 15px',
            }}
          >
            <div style={{ flex: 1, padding: 10, boxSizing: 'border-box' }}>
              <p
                className={classes.p}
                style={{
                  margin: 0,
                  color: theme.palette.text.secondary,
                }}
              >
                扫描二维码继续阅读
              </p>
            </div>
            <img src={qrBase64Img} width={70} height={70} alt="QR" />
          </div>
        </div>
      </div>
    </div>
  );

}


export default CanvasPoster;
