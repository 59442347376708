import { useEffect, useState } from 'react';

function useLocalColorScheme() {
  const [color, setColor] = useState(undefined);
  useEffect(() => {
    try {
      const local = localStorage.getItem('theme');
      if (['light', 'dark'].includes(local)) {
        setColor(local);
      } else {
        setColor('light');
      }
    } catch (e) {
    }
  });
  return color;
}

export default useLocalColorScheme;
