import { difference, isNumber, random } from 'lodash';

function useMoreTags(tags = [], { exclude = [], limit }) {
  const origin = difference([...tags], exclude);
  const limitNum =
    isNumber(limit) && limit < origin.length ? limit : origin.length; //  origin.length 生成多少条
  const tag = [];

  while (tag.length < limitNum) {
    const temp = difference(origin, tag);
    const key = temp[random(0, temp.length - 1)];
    tag.push(key);
  }
  return tag;
}

export default useMoreTags;
