import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import NProgress from 'nprogress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MDXRender from '../components/MDXRender';
import { graphql } from 'gatsby';
import Seo from '../components/seo/Seo';
import CardContent from '@material-ui/core/CardContent';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Footer from '../components/Footer';
import Divider from '@material-ui/core/Divider';
import Replacement from '../components/Replacement';
import IconButton from '@material-ui/core/IconButton';
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Pull from '../components/Pull';
import useImgUnDrag from '../hook/useImgUnDrag';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';

const avatarStyle = {
  width: 120,
  height: 120,
  borderRadius: '50%',
  overflow: 'hidden',
};

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  avatar: {
    width: 120,
    height: 120,
    // borderRadius: '50%',
    // overflow: 'hidden',
    marginBottom: 30,
    cursor: 'move',
  },
  mate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: 'rgba(0,0,0,.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  overlayContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  infoBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  infoPaper: {
    padding: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tagsBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    zIndex: 1,
  },
}));

const myTags = [
  {
    id: 'zq',
    text: '足球',
  },
  {
    id: 'hjmdl',
    text: '皇家马德里',
    s: true,
  },
  {
    id: 'ow',
    text: '守望先锋',
    s: true,
  },

  {
    id: 'yd',
    text: '阿西莫夫',
    s: true,
  },
  {
    id: 'yy',
    text: '遥远的歌',
  },
  {
    id: 'dy',
    text: '死亡诗社',
    s: true,
  },
  {
    id: 'JOJO',
    text: 'JOJO的奇妙冒险',
    s: true,
  },
];

export default function About(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { data } = props;
  const { allMdx, about } = data;

  let height = 180;
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  try {
    useImgUnDrag('mateAvatar')();
  } catch (e) {
  }

  if (mdUp) {
    height = 'auto';
  }

  useEffect(() => {
    NProgress.done();
  }, []);

  const banner = getImage(about.frontmatter.postBanner);
  const avatar = getImage(data.avatar);

  const BannerImg = banner ? (
    <GatsbyImage style={{ height }} image={banner} alt="" />
  ) : (
    <Replacement style={{ height: mdUp ? 500 : 180 }} />
  );

  const seo = {
    postMeta: {
      title: '关于 - Acuariol',
      description: '关于-Acuariol',
    },
  };

  const handleOpen = (tag) =>
    tag.s && window.open('https://www.baidu.com/s?&wd=' + tag.text, '_blank');

  const Banner = () => (
    <div style={{ position: 'relative' }}>
      {BannerImg}
      <div className={classes.overlay}>
        <PopupState variant="popper" popupId="about-me">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                color="inherit"
                className={classes.infoBtn}
                {...bindToggle(popupState)}
              >
                <InfoOutlinedIcon color="inherit" />
              </IconButton>
              <Popper
                {...bindPopper(popupState)}
                placement="top-end"
                transition
                style={{ zIndex: 100 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.infoPaper}>
                      <Typography>
                        插画来自Behance插画师Herlinde Demaerel
                      </Typography>
                      <List>
                        <ListItem
                          button
                          component="a"
                          target="_blank"
                          href="https://www.behance.net/gallery/58670507/Flowing"
                        >
                          访问
                        </ListItem>
                      </List>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </React.Fragment>
          )}
        </PopupState>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Seo {...seo} />
      {!mdUp ? (
        <Container maxWidth="lg">
          <Banner />
        </Container>
      ) : (
        <Banner />
      )}
      <Container maxWidth="lg">
        <div className={classes.mainGrid}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <div className={classes.mate}>
                    <Pull>
                      <Avatar className={classes.avatar} id="mateAvatar">
                        <GatsbyImage
                          imgStyle={avatarStyle}
                          image={avatar}
                          onDragStart={(event) => {
                            event.preventDefault();
                            return false;
                          }}
                          alt=""
                        />
                      </Avatar>
                    </Pull>

                    <Typography variant="subtitle1" gutterBottom>
                      ReactJS,&nbsp;GatsbyJS
                    </Typography>

                    <Typography variant="subtitle1" className={classes.mb2}>
                      Developer
                    </Typography>

                    <Divider style={{ width: '100%' }} />
                    <Typography variant="subtitle1" className={classes.my2}>
                      共 {allMdx.totalCount} 篇文章
                    </Typography>
                    <Divider style={{ width: '100%' }} />
                    {/*<Typography variant="subtitle1" className={classes.my2}>*/}
                    {/*  与我联系：me@acuariol.com*/}
                    {/*</Typography>*/}
                    <Divider style={{ width: '100%' }} />

                    <div className={clsx(classes.tagsBox, classes.my2)}>
                      {myTags.map((tag) => (
                        <Chip
                          onClick={() => handleOpen(tag)}
                          clickable={!!tag.s}
                          label={tag.text}
                          key={tag.id}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  {about && <MDXRender>{about.body}</MDXRender>}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Footer
          title="Acuariol.com"
          description="Captain, My Captain! 及时行乐"
        />
      </Container>
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query {
    about: mdx(slug: { eq: "about-me/" }) {
      body
      frontmatter {
        title
        description
        date(formatString: "YYYY-MM-DD")
        postBanner {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    avatar: imageSharp(
      fields: { slug: { eq: "/avatar/" }, source: { eq: "image" } }
    ) {
      gatsbyImageData(
        width: 120
        height: 120
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
    }
    allMdx(
      filter: {
        frontmatter: { createPage: { eq: true }, visible: { eq: true } }
      }
    ) {
      totalCount
    }
  }
`;
