import React from 'react';
import { Backdrop, IconButton, Popover, Tooltip } from '@material-ui/core';
import DevicesIcon from '@material-ui/icons/Devices';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
  paper: {
    borderRadius: 12,
    width: 150,
    height: 150,
  },
}));

function DevicesReviewButton({ base64Img }) {
  // const [base64Img, setBase64Img] = useState('');
  const classes = useStyles();

  // useEffect(() => {
  //
  //   QRCode.toDataURL(window.location.href || '').then(res => {
  //     setBase64Img(res);
  //   });
  //
  // }, []);

  return (
    <>
      <PopupState variant="popover" popupId="popup-popover">
        {(popupState) => (
          <>
            <Tooltip title="在移动端上继续阅读" placement="bottom">
              <IconButton {...bindTrigger(popupState)}>
                <DevicesIcon />
              </IconButton>
            </Tooltip>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{ paper: classes.paper }}
            >
              <img src={base64Img} width="100%" height="100%" alt="" />
            </Popover>

            <Backdrop
              classes={{ root: classes.backdrop }}
              open={popupState.isOpen}
            />
          </>
        )}
      </PopupState>
    </>
  );
}

export default DevicesReviewButton;
