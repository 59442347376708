import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '106px',
  },
}));

export default function Not404() {
  const classes = useStyles();

  useEffect(() => {
    NProgress.done();
  }, []);

  const back = () => {
    try {
      window.history.back();
    } catch (e) {
    }
  };

  return (
    <div>
      <div style={{ height: '10vh' }} />
      <Typography variant="h3" align="center">
        We lost this page!
      </Typography>
      <Typography className={classes.header} align="center">
        404
      </Typography>

      <Grid container direction="row" justify="center" alignItems="center">
        <Button
          onClick={back}
          color="secondary"
          variant="contained"
          startIcon={<ArrowBackIosIcon />}
        >
          Go Back
        </Button>
      </Grid>
    </div>
  );
}
