const common = {
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2.5rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.725rem',
    },
  },
};

const dark = {
  ...common,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '::selection': {
          color: '#000',
          backgroundColor: '#fff',
        },
      },
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#fff',
    },
    background: {
      default: '#080808',
      paper: '#151515',
    },
  },
};

const light = {
  ...common,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '::selection': {
          color: '#fff',
          backgroundColor: '#000',
        },
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#000',
    },
    background: {
      default: '#fafafa',
    },
  },
};

const allTheme = { light, dark };
export default allTheme;
