import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import cateMapping from '../const/cateMapping';
import { navigateTo } from '../utils/utils';
import useMeasure from 'react-use-measure';

// const useStyles = makeStyles((theme) => ({
//   sidebarSection: {
//     marginTop: theme.spacing(3),
//   },
//   chip: {
//     marginRight: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
// }));

export default function Sidebar(props) {
  // const classes = useStyles();
  const { social } = props;
  const cate = [...cateMapping];
  const [ref, bounds] = useMeasure({ scroll: true });
  const nav = (c) => navigateTo({ path: `/categories/${c}` });
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { top, width } = bounds;

  const sticky = top <= 30;

  const styles = React.useMemo(
    () => ({
      position: sticky ? 'fixed' : 'relative',
      top: sticky ? 30 : 0,
      width,
      opacity: width === 0 ? 0 : 1,
      zIndex: width === 0 ? -999 : 1,
    }),
    [sticky, width],
  );

  return (
    <Grid item xs={12} md={4}>
      <div ref={ref}>
        <div style={styles}>
          {mdUp && (
            <Paper>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  专辑
                </Typography>
                <List component="nav">
                  {cate.map((item, index) => (
                    <Fragment key={item[0]}>
                      <ListItem button onClick={() => nav(item[0])}>
                        <ListItemText primary={item[1].title} />
                      </ListItem>
                      {index < cate.length - 1 && <Divider />}
                    </Fragment>
                  ))}
                </List>
              </CardContent>
            </Paper>
          )}

          <Paper style={{ marginTop: 16 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                社交
              </Typography>

              <List component="nav">
                {social.map((network) => (
                  <Fragment key={network.name}>
                    <ListItem button>
                      <ListItemIcon>
                        <network.icon />
                      </ListItemIcon>
                      <ListItemText primary={network.name} />
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </CardContent>
          </Paper>
        </div>
      </div>
    </Grid>
  );
}

Sidebar.propTypes = {
  archives: PropTypes.array,
  description: PropTypes.string,
  social: PropTypes.array,
  title: PropTypes.string,
};
