import React from 'react';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';

const BgElement = Element.BgElement;

const height = 350;

const useStyles = makeStyles((theme) => ({
  banner: {
    height: height,
    [theme.breakpoints.down('sm')]: {
      height: height - 50,
    },
    position: 'relative',
    transition: 'all 0.3s ease-in',
  },
  bannerElem: {
    position: 'relative',
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#f5f5f5',
  },
  headerTitle: {
    // fontFamily: `'Concert One', cursive`,
    paddingTop: 130,
    fontSize: '3rem',
    transition: 'all 0.3s ease-in',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },
  },
  white: {
    color: '#fff',
  },
  black: {
    color: '#333',
  },
}));

const staticImageStyles = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
};

const list = [
  {
    id: 1,
    title: 'Hi! Nice to meet you',
    subtitle: '',
    bg: '',
    color: 'white',
    cover: (
      <StaticImage
        style={staticImageStyles}
        src="../../static/assets/banner1.jpg"
        alt=""
        placeholder="tracedSVG"
        layout="fullWidth"
      />
    ),
  },
  {
    id: 2,
    title: '海内存知己，天涯若比邻',
    subtitle: '',
    bg: '',
    color: 'white',
    cover: (
      <StaticImage
        style={staticImageStyles}
        src="../../static/assets/banner2.jpg"
        alt=""
        placeholder="tracedSVG"
        layout="fullWidth"
      />
    ),
  },
];

function HomeBanner() {
  const classes = useStyles();
  return (
    <BannerAnim prefixCls={classes.banner} autoPlay autoPlaySpeed={5000}>
      {list.map((item) => (
        <Element prefixCls={classes.bannerElem} key={'banner' + item.id}>
          <BgElement
            key={item.id + 'bg'}
            className={classes.bg}
            // style={{
            //   backgroundImage: `url(${item.bg})`,
            // }}
          >
            {item.cover}
          </BgElement>
          <Typography align="center" variant="h2" className={clsx(classes.headerTitle, classes[item.color])}>
            <TweenOne animation={{ y: 30, opacity: 0, type: 'from' }}>{item.title}</TweenOne>
          </Typography>

          {item.subtitle && (
            <Typography
              align="center"
              variant="subtitle1"
              className={clsx(classes[item.color])}
            >
              <TweenOne
                animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
              >
                {item.subtitle}
              </TweenOne>
            </Typography>
          )}
        </Element>
      ))}
    </BannerAnim>
  );
}

export default HomeBanner;
