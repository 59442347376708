import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import NProgress from 'nprogress';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import BaseLayout from '../layout/BaseLayout';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Footer from '../components/Footer';
import { navigateTo } from '../utils/utils';
import Tag from '../components/Tag';
import useTags from '../hook/useTags';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: '50vh',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    maxWidth: 760,
    margin: `${theme.spacing(6)}px auto`,

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      margin: `${theme.spacing(4)}px auto`,
    },
  },
  total: {
    marginLeft: 'auto',
  },
  action: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
  },
}));

const TagPage = ({ data }) => {
  const { allMdx, site } = data;

  const classes = useStyles();

  useEffect(() => {
    NProgress.done();
  }, []);

  const mapping = useTags(get(allMdx, 'edges', []));

  const tags = Array.from(Object.keys(mapping)).sort(
    (b, a) => mapping[a] - mapping[b],
  );
  const { title } = site.siteMetadata;

  const nav = (tag) => navigateTo({ path: `/tag/${tag}` });

  return (
    <React.Fragment>
      <Helmet>
        <title>{`所有标签 - ${title}`}</title>
        <meta name="description" content={`所有标签 - ${title}`} />
      </Helmet>

      <BaseLayout>
        <Typography
          className={classes.title}
          variant="h5"
          component="h5"
          gutterBottom
        >
          所有标签
        </Typography>
        <Divider />

        <div className={classes.content}>
          {tags.map((item) => (
            <Tag label={item} key={item} count={mapping[item]} onClick={nav} />
          ))}
        </div>
      </BaseLayout>
      <Footer
        title="Acuariol.com"
        description="Captain, My Captain! 及时行乐"
      />
    </React.Fragment>
  );
};

export default TagPage;

export const pageQuery = graphql`
  query getAllTags {
    allMdx(
      filter: {
        frontmatter: { visible: { eq: true }, createPage: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
