import WrapWithProvider from './src/components/WrapWithProvider';
// import NProgress from 'nprogress';

export const wrapRootElement = WrapWithProvider;

require('./static/global.css');
require('prismjs/themes/prism-tomorrow.css');

// export const onRouteUpdate = () => {
//   NProgress.done();
// }
