import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import { useHover } from 'react-use-gesture';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  content: {
    width: 130,
    height: 50,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 45,
    },
    borderRadius: 4,
    transition: 'all 0.3s ease-in',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noUserSelect: {
    userSelect: 'none',
    msUserSelect: 'none',
  },
}));

function Tag({ label, count, onClick }) {
  const classes = useStyles();
  const [ev, set] = useState(false);
  // const [pos, setPos] = useState({ clientX: 0, clientY: 0 });

  const bind = useHover(({ active }) => {
    set(active);
  });

  // const onMouseDown = ({ clientX, clientY }) => {
  //   setPos({ clientX, clientY });
  // };

  const handleClick = ({ clientX, clientY }) => {
    // const { clientX: startX, clientY: startY } = pos;
    // if (Math.abs(startX - clientX) > 10 || Math.abs(startY - clientY) > 10) {
    //   return;
    // }
    onClick(label);
  };

  return (
    <div className={classes.root}>
      <Badge
        badgeContent={count}
        color="secondary"
        className={classes.noUserSelect}
      >
        <CardActionArea onClick={handleClick}>
          <Card {...bind()} className={classes.content} elevation={ev ? 3 : 1}>
            <Typography className={classes.noUserSelect} variant="subtitle1">
              {label}
            </Typography>
          </Card>
        </CardActionArea>
      </Badge>
    </div>
  );
}

export default Tag;
