import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BaseLayout from '../layout/BaseLayout';
import Paper from '@material-ui/core/Paper';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { getImage } from 'gatsby-plugin-image';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { delay, get } from 'lodash';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import MDXRender from '../components/MDXRender';
import NextPost from '../components/NextPost';
import { navigateTo } from '../utils/utils';
import Seo from '../components/seo/Seo';
import Footer from '../components/Footer';
import { Chip } from '@material-ui/core';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { useStore } from 'react-redux';
// import RecommendRead from '../components/RecommendRead';
import useWidth from '../hook/useWidth';
import ShareButton from '../components/ShareButton';
import CommonBannerImg from '../components/CommonBannerImg';
import DevicesReviewButton from '../components/DevicesReviewButton';
import QRCode from 'qrcode';
import NProgress from 'nprogress';
import loadable from '@loadable/component';

import { InView } from 'react-intersection-observer';

const RecommendRead = loadable(() => import('../components/RecommendRead'));

const useStyles = makeStyles((theme) => ({
  marginBottom4: {
    marginBottom: theme.spacing(6),
  },
  media: {
    height: 320,
    backgroundColor: theme.palette.grey[800],
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  p: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  bold: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  paper: {
    position: 'relative',
    // [theme.breakpoints.down('sm')]: {
    //   boxShadow: 'none',
    // },
  },
  cardContent: {
    padding: '1rem 2rem 2rem',
    [theme.breakpoints.down('md')]: {
      padding: '0.6rem',
    },
  },
  meta: {
    // paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  iconBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tagsBox: {
    marginBottom: theme.spacing(6),
  },
  sticky: {
    // position: 'fixed',
    // top: 30,
    // zIndex:9,
    // padding:theme.spacing(1),
    borderRadius: 32,
    border: 'none',
    boxShadow: 'none',
  },
}));

export default function PostTemplate(props) {
  const classes = useStyles();

  const {
    data,
    pageContext: { next, previous, id },
  } = props;
  const { mdx, site } = data;
  const { title, subtitle } = site.siteMetadata;

  const {
    title: postTitle,
    description: postDescription,
    category,
  } = mdx.frontmatter;

  const excerpt = get(mdx, 'excerpt');
  const categorySlug = get(mdx, 'fields.categorySlug', null);

  const postBanner = getImage(mdx.frontmatter.postBanner.banner);
  // const smallCover = getImage(mdx.frontmatter.postBanner.smallCover);
  const tags = get(mdx, 'frontmatter.tags', []);

  const [qrBase64Img, setQrBase64Img] = useState('');

  const key = useWidth();

  const smDown = ['xs', 'sm'].includes(key);

  useEffect(() => {
    NProgress.done();
    QRCode.toDataURL(window.location.href || '').then((res) => {
      setQrBase64Img(res);
    });
  }, []);

  const nav = (popupState) => {
    popupState.close();
    delay(() => {
      navigateTo({ path: categorySlug });
    }, 300);
  };

  const store = useStore();
  const { dispatch, getState } = store;
  const handleChangeFontSize = (type, popupState) => {
    const { fontSize } = getState();
    popupState?.close();

    dispatch({
      type: 'setFontSize',
      payload: {
        value: type === '+' ? fontSize + 2 : 16,
      },
    });
  };

  const seo = {
    postMeta: {
      title: `${postTitle} - ${title}`,
      description: excerpt,
      subtitle,
    },
  };

  const [inView, setInView] = React.useState(false);

  const inViewChange = (inView) => {
    setInView(inView);
  };

  return (
    <React.Fragment>
      <Seo {...seo} />
      <BaseLayout>
        <div className={classes.marginBottom4}>
          <Paper className={classes.paper}>
            <CommonBannerImg img={postBanner} smDown={smDown} />

            <CardContent className={classes.cardContent}>
              <div className={classes.iconBar}>
                <Paper className={classes.sticky}>
                  {key !== 'xs' && (
                    <DevicesReviewButton base64Img={qrBase64Img} />
                  )}

                  <Tooltip title="放大正文字体" placement="bottom">
                    <IconButton onClick={() => handleChangeFontSize('+')}>
                      <TextFieldsIcon />
                    </IconButton>
                  </Tooltip>

                  <ShareButton
                    qrBase64Img={qrBase64Img}
                    excerpt={excerpt}
                    title={postTitle}
                    date={mdx.frontmatter.date}
                    postBanner={postBanner}
                    mediaKey={key}
                  />

                  {categorySlug && (
                    <PopupState variant="popover" popupId="popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <Tooltip title="更多" placement="bottom">
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                          </Tooltip>

                          <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => nav(popupState)}>
                              查看该专辑
                            </MenuItem>

                            <MenuItem
                              onClick={() =>
                                handleChangeFontSize('-', popupState)
                              }
                            >
                              还原正文字体
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  )}
                </Paper>
              </div>
              <Typography className={classes.title} variant="h4">
                {postTitle}
              </Typography>
              <Divider />
              <div className={classes.meta}>
                <Typography className={classes.p} variant="body1" component="p">
                  {mdx.frontmatter.date}
                </Typography>
                {postDescription && (
                  <Typography
                    className={clsx(classes.p, classes.bold)}
                    variant="body1"
                    component="p"
                  >
                    {postDescription}
                  </Typography>
                )}
              </div>
              <MDXRender category={category}>{mdx.body}</MDXRender>

              {category === 'developing' && (
                <MuiAlert severity="info">
                  <Typography variant="body1">
                    本文遵循
                    <Typography
                      color="textSecondary"
                      component="a"
                      target="_blank"
                      href="https://creativecommons.org/licenses/by-sa/4.0/deed.zh"
                    >
                      <strong>CC BY-SA 4.0 </strong>
                    </Typography>
                    版权协议 ，转载请附上出处链接及本声明。
                  </Typography>
                </MuiAlert>
              )}
            </CardContent>
          </Paper>
        </div>

        <div className={classes.tagsBox}>
          {tags.map((tagName) => (
            <Chip
              label={tagName}
              key={tagName}
              clickable
              icon={<LocalOfferOutlinedIcon fontSize="small" />}
              variant="outlined"
              color="secondary"
              onClick={() => navigateTo({ path: `/tag/${tagName}` })}
              className={classes.chip}
            />
          ))}
        </div>

        <NextPost next={next} previous={previous} mdUp={!smDown} />

        <InView threshold={1} onChange={inViewChange} triggerOnce>
          <div style={{ minHeight: 64 }} />
        </InView>

        {inView && <RecommendRead id={id} breakpointsKey={key} />}
      </BaseLayout>
      <Footer
        title="Acuariol.com"
        description="Captain, My Captain! 及时行乐"
      />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        categorySlug
      }
      excerpt(pruneLength: 100, truncate: true)
      frontmatter {
        cover
        title
        date(formatString: "YYYY-MM-DD")
        description
        category
        tags
        postBanner {
          banner: childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
          
        }
      }
    }
  }
`;



// dfdf








// smallCover: childImageSharp {
//   gatsbyImageData(
//     layout: FIXED
//   width: 450
//   height: 410
//   formats: [AUTO, WEBP, AVIF]
// )
// }
