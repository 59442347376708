import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import { navigateTo } from '../utils/utils';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { connect, useStore } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import TextAni from 'rc-texty';
import 'rc-texty/assets/index.css';
import useRandomTextAni from '../hook/useRandomTextAni';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
// import SearchModal from './SearchModal';
import loadable from '@loadable/component';

const SearchModal = loadable(() => import('./SearchModal'));

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 0,
    margin: 0,
  },
  toolbarTitle: {
    flex: 1,
    fontFamily: `'Carter One', cursive`,
    padding: `0`,
    fontSize: theme.typography.h5.fontSize,
    transition: 'all 0.3s ease',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.8rem',
      padding: theme.spacing(4, 0),
    },
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    padding: 0,
    margin: theme.spacing(0, 0, 1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 0, 4),
    },
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.subtitle1,
    },
  },
  list: {
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
}));

function Header(props) {
  const isDark = props.themeType === 'dark';
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const textAniProps = useRandomTextAni();

  const result = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          menu {
            label
            path
            name
            outUrl
          }
        }
      }
    }
  `);

  const menus = get(result, 'site.siteMetadata.menu', []);

  const classes = useStyles();
  const { title } = props;

  const handleMenuClick = (menu) => {
    if (menu.name) {
      switch (menu.name) {
        case 'setting':
          setOpen(true);
          break;
      }
    }
    if (menu.path) {
      navigateTo({ path: menu.path });
    }
    if (menu.outUrl) {
      navigateTo({ outUrl: menu.outUrl });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    props.dispatch({
      type: 'ToggleTheme',
      payload: { theme: isDark ? 'light' : 'dark' },
    });
  };

  const { dispatch } = useStore();

  const resetStore = () => {
    dispatch({
      type: 'resetStore',
    });
  };
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h2"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          <TextAni duration={640} delay={200} {...textAniProps}>
            {title}
          </TextAni>
        </Typography>
      </Toolbar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={8}>
          <Toolbar
            component="nav"
            variant="dense"
            className={clsx(classes.toolbarSecondary, 'scrollbar')}
          >
            {menus.map((menu) => (
              <Button
                color="inherit"
                key={menu.label}
                onClick={() => handleMenuClick(menu)}
                className={classes.toolbarLink}
              >
                {menu.label}
              </Button>
            ))}

            <IconButton onClick={() => setOpenSearch(true)}>
              <SearchIcon />
            </IconButton>
          </Toolbar>
        </Grid>
      </Grid>

      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={handleClose}
        aria-labelledby="confirmation-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle id="confirmation-dialog-title" color="textSecondary">
          设置
        </DialogTitle>
        <DialogContent dividers>
          <List className={classes.list}>
            <ListItem>
              <ListItemIcon>
                <Brightness4Icon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-wifi" primary="暗色主题" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={isDark}
                  onChange={handleToggle}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={resetStore}>
            重置设置
          </Button>
          <Button onClick={() => setOpen(false)}>关闭</Button>
        </DialogActions>
      </Dialog>

      <SearchModal open={openSearch} onClose={() => setOpenSearch(false)} />
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};

const HeaderConnect = connect((state) => ({ themeType: state.theme }))(Header);
export default HeaderConnect;
